<div *ngIf="!next" class="upload-document">
  <div class="upload-document__wrapper">
    <h3>upload document</h3>
    <div class="search">
      <input [(ngModel)]="filter" type="text" placeholder="Search"/>
    </div>
    <p>Documents pending upload:</p>
    <ul class="upload-document__list">
      <ng-container *ngFor="let doc of documentsList">
        <li *ngIf="checkShowDoc(doc.name)" (click)="toggleStep(doc)" class="upload-document__list-item">
          <p>{{ doc.name }}</p>
          <i class="icon-back-arrow"></i>
        </li>
      </ng-container>
    </ul>
  </div>
</div>

<div *ngIf="next" class="page-document">
  <div class="page-document__wrapper">
    <div class="back-btn-wrapper">
      <span (click)="toggleStep()" [class.blocked]="blockBackButton">
        <i class="icon-back-arrow"></i> Back to all
      </span>
    </div>
    <h3>{{ selectedDocument.name }}</h3>
    <p>Please select page of the document:</p>
    <ul [class.pointer-events-none]="disableSelectPage" class="page-list">
      <li *ngFor="let page of selectedDocument.pages; let i = index" class="page-list__item">
        <div (click)="setPage(i + 1)" [class.error-radio-item]="error && error[i]" class="radio-item select">
          <input
            [attr.id]="'select-page' + i"
            [checked]="page.checked"
            name="radio-group"
            type="radio"/>
          <label [attr.for]="'select-page' + i" class="page-label">
            <span class="status-name">
              Page {{ i + 1 }}
              <span [class.status-name__uploaded]="page.title === PageUploadTextEnum.PageUploaded">
                {{ page?.title }}
              </span>
            </span>
            <div *ngIf="page && page.title === PageUploadTextEnum.PageUploaded" class="selected-file__icon"></div>
          </label>
          <span *ngIf="error && error[i]" class="err-message">{{ error[i] }}</span>
        </div>
      </li>
    </ul>
    <div class="btn-wrap">
      <button (click)="uploadDocument(selectedDocument)" class="btn violet">
        Select File
      </button>
    </div>
  </div>
</div>
